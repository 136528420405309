import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'

import {
  Action,
  NextTab as NextTabComponent,
} from '@pretto/website/src/templates/blocs/rows/secondaryMenu/components/NextTab'

import styled, { css } from 'styled-components'

interface NextTabProps {
  action: Action
  $display: boolean
}

interface BlocRowProps {
  $color: string
}

export const Grid = styled.div<BlocRowProps>`
  ${grid()};
  ${({ theme }) => theme.typos.body4};
  background-color: ${({ $color, theme }) => theme.colors[$color]};
  padding-top: 0px;
  padding-bottom: 0px;
`

export const SecondaryMenuContainer = styled.div`
  ${column([1, 6])};
  position: relative;
  display: flex;
  flex-shrink: 0;
  width: 100%;
  justify-content: flex-start;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([1, 10])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([3, 10])};
  }
`

export const NextTab = styled(NextTabComponent)<NextTabProps>`
  display: none;

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: block;
    visibility: ${({ $display }) => ($display ? 'visible' : 'hidden')};
    opacity: ${({ $display }) => ($display ? 1 : 0)};
    transition:
      visibility 0s,
      opacity 0.2s ease-in-out;
    position: absolute;
    top: 0;
    ${({ action }) =>
      action === 'previous'
        ? css`
            left: 0;
          `
        : css`
            right: 0;
          `};
  }
`

export const ScrollableContainer = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  width: 100%;
  justify-content: flex-start;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scroll-padding: ${g(2, 4)};
  scroll-snap-type: x mandatory;
  will-change: scroll-position;

  &::-webkit-scrollbar {
    display: none;
    width: 0px !important;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    gap: ${g(1)};
  }
`

export const PrevNextContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`
